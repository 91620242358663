<template>
  <div class="trademanagement">
    <div class="users_form">
      <el-button type="primary" @click="trademanagementadd">添加</el-button>
    </div>

    <el-table
      v-loading="tableLoading"
      :data="tableData"
      :border="true"
      row-key="id"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      class="table"
    >
      <el-table-column label="行業名稱" prop="industry_tiltle">
      </el-table-column>
      <el-table-column prop="sort" label="排序" width="120"> </el-table-column>
      <el-table-column prop="status" label="狀態">
        <template slot-scope="scope">{{scope.row.status == 0?'顯示': '隱藏'}}</template>
      </el-table-column>
      <el-table-column prop="c_time" label="創建時間" width="120">
      </el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="info"
            icon="el-icon-edit"
            @click="compile(scope.row.id)"
            >編輯</el-button
          >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="compileadd(scope.row)"
            >添加</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="trademanagementopen(scope.row.id)"
            >刪除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加 -->
    <el-dialog
      title="添加行業"
      :visible.sync="dialogVisible"
      width="400px"
      :before-close="handleClose"
      class="dialog"
    >
      <el-form ref="form" :model="tableDataobj" :rules="rules">
        <el-form-item label="標題" prop="name" class="form_item">
          <el-input v-model="tableDataobj.industry_tiltle" placeholder="請輸入標題"></el-input>
        </el-form-item>
        <el-form-item label="排序" class="form_item">
          <el-input v-model="tableDataobj.sort" placeholder="請輸入內容"></el-input>
        </el-form-item>
        <el-form-item label="特殊資源" prop="status">
          <el-radio-group v-model="tableDataobj.status">
            <el-radio :label="0">顯示</el-radio>
            <el-radio :label="1">隱藏</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="mentadd">確 定</el-button>
      </span>
    </el-dialog>

    <!-- 編輯 -->
    <el-dialog
      title="編輯行業"
      :visible.sync="dialog"
      width="400px"
      class="dialog"
      :before-close="handle"
    >
      <el-form ref="form" :model="properties" :rules="rules">
        <el-form-item label="上級行業" class="form_item">
          <el-input v-model="properties.p_industry_tiltle" disabled></el-input>
        </el-form-item>
        <el-form-item label="標題" class="form_item">
          <el-input v-model="properties.industry_tiltle" placeholder="請輸入標題"></el-input>
        </el-form-item>
        <el-form-item label="排序" class="form_item">
          <el-input v-model="properties.sort" placeholder="請輸入內容"></el-input>
        </el-form-item>
        <el-form-item label="特殊資源" >
          <el-radio-group v-model="properties.status">
            <el-radio :label="0">顯示</el-radio>
            <el-radio :label="1">隱藏</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="compilebian">確 定</el-button>
      </span>
    </el-dialog>

    <!-- 列表添加 -->
    <el-dialog
      title="添加行業"
      :visible.sync="Visible"
      width="400px"
      class="dialog"
      :before-close="Close"
    >
      <el-form ref="form" :model="tableobjs" :rules="rules">
        
        <el-form-item label="上級行業" class="form_item">
          <el-input disabled v-model="tableobjs.p_industry_tiltle"></el-input>
        </el-form-item>
        <el-form-item label="標題" class="form_item">
          <el-input v-model="tableobjs.industry_tiltle" placeholder="請輸入標題"></el-input>
        </el-form-item>
        <el-form-item label="排序" class="form_item">
          <el-input v-model="tableobjs.sort" placeholder="請輸入內容"></el-input>
        </el-form-item>
        <el-form-item label="特殊資源" >
          <el-radio-group v-model="tableobjs.status">
            <el-radio :label="0">顯示</el-radio>
            <el-radio :label="1">隱藏</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="Visible = false">取 消</el-button>
        <el-button type="primary" @click="addcompiletian">確 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      tableLoading: false,
      expands: [],
      dialogVisible: false,
      dialog:false,
      Visible:false,
      tableDataobj: {
        id: "",
        industry_tiltle: "",
        sort: "",
        status: "",
        pid: "",
      },
      tableobj: {
        id: "",
        industry_tiltle: "",
        sort: "",
        status: "",
        pid: "",
      },

      tableobjs: {
        id: "",
        industry_tiltle: "",
        sort: "",
        status: "",
        pid: "",
        p_industry_tiltle: ''
      },
      properties:{},
      rules: {},
      prveIndustry: ''
    };
  },
  methods: {
    async gettrademanagement() {
      this.tableLoading = true
      const res = await this.$api.gettrademanagement();
      this.tableLoading = false
      this.tableData = res.data.data;
    },
    // 添加
    trademanagementadd() {
      this.dialogVisible = true;
      this.tableDataobj.industry_tiltle = ''
      this.tableDataobj.sort = ''
      this.tableDataobj.status = ''
    },
    async mentadd() {
        const res = await this.$api.mentadd(this.tableDataobj);
        if(res.data.code == 200) {
          this.$message({
            type: 'success',
            message: '添加成功'
          })
          this.gettrademanagement()
          this.dialogVisible = false
        } else {
          this.$message.error('添加失敗')
        }
    },
    // 編輯
    async compile(id) {
      this.dialog=true
      const res = await this.$api.mentaddcompile({id:id});
        if(res.data.data.pid == 0) {
          res.data.data.pid = '無上級'
        }
        this.properties=res.data.data
    },

    // 編輯提交
    async compilebian(){
      if(this.properties.pid == '無上級') {
        this.properties.pid = 0
      }
      this.tableobj=this.properties
      const res = await this.$api.compilebianj(this.tableobj);
      if(res.data.code == 200) {
        this.$message({
          type:'success',
          message: '修改成功'
        })
        this.gettrademanagement();
        this.dialog=false
      } else {
        this.$message.error('修改失敗')
      }
    },
    // 子級添加確定
    compileadd(row){
        this.tableobjs.pid=row.id
        this.tableobjs.p_industry_tiltle = row.industry_tiltle
        this.tableobjs.status = ''
        this.tableobjs.sort = ''
        this.tableobjs.industry_tiltle = ''
        this.Visible=true
    },
    async addcompiletian(){
        const res = await this.$api.addcompiletian(this.tableobjs);
        if(res.data.code == 200) {
          this.$message({
            type: 'success',
            message: '添加成功'
          })
          this.gettrademanagement();
          this.Visible=false
        } else {
          this.$message.error('添加失敗')
        }
    },
    // 刪除
    async trademanagementopen(id) {
      const ress = await this.$confirm(
        "此操作將永久刪除該用戶, 是否繼續?",
        "提示",
        {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (ress !== "confirm") {
        this.$message.info("取消刪除");
      }
      await this.$api.trademanagementopen({ id: id });
      if(res.data.code == 200) {
        this.gettrademanagement();
        this.$message.success("刪除成功");
      } else {
        this.$message.error("刪除失敗");
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handle(){
        this.dialog=false
    },
    Close(){
        this.Visible=false
    }
  },
  created() {
    this.gettrademanagement();
  },
};
</script>

<style lang="scss" scoped>
.trademanagement {
  .users_form {
    padding: 5px 0px;
    display: flex;
    align-items: center;

    .el-form {
      width: 300px;
      height: 40px;
    }

    .el-button {
      margin: 0px 10px;
    }
  }
  .dialog{
      .el-dialog__body{
          .el-form{
              .el-form-item{
                  .el-input{
                      width: 70%;
                  }
              }
                .form_item{
                    margin-left: 28px;
                }
          }
      }
  }
}
</style>